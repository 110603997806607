import type { IVaultType } from "./assets";

export const YIELD_ACTION = {
  Append: "enums.yield.action.append",
  Subscribe: "enums.yield.action.subscribe",
  Redeem: "enums.yield.action.redeem"
};

// 未枚举翻译的type 为目前不需要展示 或者不需要中文
export const BUSINESS_TYPE = {
  1: "enums.business.type.withdraw",
  2: "enums.business.type.deposit",
  3: "order",
  4: "inner withdraw",
  5: "reward",
  6: "settle",
  7: "otc order",
  8: "inner transfer",
  9: "agent_manual_settle",
  10: "enums.business.type.yield",
  11: "treasure confirm",
  12: "enums.business.type.interests",
  13: "sys_invest",
  14: "treasure trial cash revenue transfer",
  15: "enums.business.type.yield",
  23: "member pay",
  24: "enums.business.type.spot.trade",
  25: "enums.business.type.fiat.deposit",
  26: "enums.business.type.fiat.withdraw",
  27: "redeem card",
  28: "system plus",
  29: "dummy transaction",
  30: "redeem card buy",
  31: "enums.business.type.dca",
  32: "margin transaction",
  33: "margin interest",
  34: "wallet fee",
  35: "dust transaction",
  36: "hedge transaction",
  37: "enums.business.type.autoInvest",
  38: "enums.business.type.fixed.subscribe",
  39: "enums.business.type.fixed.subscribe.cancel",
  40: "fixed deposit revenue to proxy",
  41: "enums.business.type.fixed.redeem",
  44: "enums.business.type.staking",
  45: "enums.business.type.rewards",
  46: "enums.business.type.staking",
  50: "enums.business.type.dual",
  53: "enums.business.type.accumulator",
  55: "enums.business.type.snowball",
  80: "strategy",
  81: "enums.business.type.aum.fee",
  82: "enums.business.type.commission",
  83: "enums.business.type.strategy.subscription",
  84: "enums.business.type.strategy.redemption",
  85: "strategy income",
  86: "enums.business.type.strategy.subscription.cancel",
  90: "p2p merchant apply",
  91: "p2p merchant post ads",
  92: "p2p order",
  93: "p2p order fee",
  94: "p2p transfer in",
  95: "p2p transfer out",
  113: "enums.business.type.transfer.from.vault"
};

//wallet充提币历史状态
export const WALLET_STATUS = {
  WITHDRAW_APPLY: "enums.wallet.status.apply",
  WITHDRAW_APPROVED: "enums.wallet.status.approved",
  WITHDRAW_REJECTED: "enums.wallet.status.rejected",
  WITHDRAW_COMPLETED: "enums.wallet.status.completed",
  WITHDRAW_FAILED: "enums.wallet.status.failed",
  WITHDRAW_NOT_IN_CHAIN: "enums.wallet.status.notInChain",
  DEPOSIT_INITIAL: "enums.wallet.status.initial",
  DEPOSIT_APPLY: "enums.wallet.status.apply",
  DEPOSIT_FINISHED: "enums.wallet.status.completed",
  DEPOSIT_FAILED: "enums.wallet.status.failed",
  DEPOSIT_REJECTED: "enums.wallet.status.rejected"
};

export const VAULT_WALLET_STATUS: { [key: string]: string } = {
  DEPOSIT_APPLY: "enums.vault.history.status.confirming",
  DEPOSIT_FINISHED: "enums.vault.history.status.completed",
  DEPOSIT_FAILED: "enums.vault.history.status.failed",
  WITHDRAW_APPLY: "enums.vault.history.status.confirming",
  WITHDRAW_APPROVED: "enums.vault.history.status.processing",
  WITHDRAW_REJECTED: "enums.vault.history.status.rejected",
  WITHDRAW_COMPLETED: "enums.vault.history.status.completed",
  WITHDRAW_FAILED: "enums.vault.history.status.failed",
  WITHDRAW_CANCELED: "enums.vault.history.status.cancel" // 用户撤销→Cancel 撤销
};

export const VAULT_ACTION_ORIGINAL_TYPE: { [key: string]: string } = {
  NORMAL: "enums.vault.normal",
  INNER_WALLET: "enums.vault.inner.wallet"
};

export const DEPOSIT_WITHDRAW_ORIGINAL_TYPE: { [key: string]: string } = {
  NORMAL: "enums.operation.type.normal",
  INNER_ACCOUNT: "enums.operation.type.inner.account",
  INNER_WALLET: "enums.operation.type.inner.wallet"
};

export const VAULT_ACTION_TYPE: { [key: string]: IVaultType } = {
  STORAGE: "storage",
  WITHDRAW: "withdraw"
};

//circle充提币历史
export const CIRCLE_STATUS = {
  FIAT_INIT: "enums.circle.status.reviewing",
  FIAT_APPLY: "enums.circle.status.reviewing",
  FIAT_APPROVED: "enums.circle.status.approved",
  FIAT_REJECTED: "enums.circle.status.rejected",
  FIAT_FAILED: "enums.circle.status.failed",
  FIAT_COMPLETED: "enums.circle.status.transferred",
  FIAT_CANCELED: "enums.circle.status.cancel"
};

//circle 绑定银行账户拒绝原因
export const CIRCLE_REJECT = {
  bankError001: "enums.circle.reject.reason.bankError001",
  bankError002: "enums.circle.reject.reason.bankError002",
  bankError003: "enums.circle.reject.reason.bankError003",
  bankError004: "enums.circle.reject.reason.bankError004",
  bankError005: "enums.circle.reject.reason.bankError005",
  bankError006: "enums.circle.reject.reason.bankError006",
  bankError007: "enums.circle.reject.reason.bankError007",
  accountError001: "enums.circle.reject.reason.accountError001",
  accountError002: "enums.circle.reject.reason.accountError002",
  certificateError001: "enums.circle.reject.reason.certificateError001",
  certificateError002: "enums.circle.reject.reason.certificateError002",
  certificateError003: "enums.circle.reject.reason.certificateError003",
  otherError001: "enums.circle.reject.reason.otherError001"
};

//定投频率
export const AUTOMATICE_INVESTMENT_CYCLE = {
  DAY: {
    code: 0,
    text: "enums.autoInvestment.cycle.daily"
  },
  WEEK: {
    code: 1,
    text: "enums.autoInvestment.cycle.weekly"
  },
  HALF_MONTH: {
    code: 2,
    text: "enums.autoInvestment.cycle.halfMonth"
  },
  MONTH: {
    code: 3,
    text: "enums.autoInvestment.cycle.monthly"
  },
  REAL_TIME: {
    code: 4,
    text: "enums.autoInvestment.cycle.daily"
  }
};
//定投周期
export const AUTOMATICE_INVESTMENT_CYCLE_CODE = {
  0: "enums.autoInvestment.cycle.daily",
  1: "enums.autoInvestment.cycle.weekly",
  2: "enums.autoInvestment.cycle.halfMonth",
  3: "enums.autoInvestment.cycle.monthly",
  4: "enums.autoInvestment.cycle.daily"
};
//我的定投状态
export const MY_AUTOMATICE_INVESTMENT = {
  0: "enums.autoInvestment.status.active",
  1: "enums.autoInvestment.status.active",
  2: "enums.autoInvestment.status.cancelled",
  3: "enums.autoInvestment.status.cancelled"
};
//我的定投类型
export const MY_AUTOMATICE_INVESTMENT_TYPE = {
  0: "enums.autoInvestment.type.DCA",
  1: "enums.autoInvestment.type.principal",
  2: "enums.autoInvestment.type.double"
};
//定投历史状态
export const AUTOMATICE_INVESTMENT_HISTORY = {
  1: "enums.autoInvestment.history.status.processing",
  2: "enums.autoInvestment.history.status.processing",
  3: "enums.autoInvestment.history.status.successful",
  11: "enums.autoInvestment.history.status.failure"
};
// 活期理财历史类型
export const FLEXIBLE_YIELD_OPERATE_TYPE = {
  1: "enums.yield.opearte.type.subscription",
  5: "enums.yield.opearte.type.redemption",
  7: "enums.yield.opearte.type.revoke",
  8: "enums.yield.opearte.type.interests",
  9: "enums.yield.opearte.type.autoInvest"
};

//操作人类别  0本人操作，1子账号，2代理商，3管理员账号
export const OPERARTOR_TYPE = {
  0: "enums.operartor.type.self",
  1: "enums.operartor.type.multi",
  2: "enums.operartor.type.partner",
  3: "enums.operartor.type.manager",
  4: "enums.operartor.type.manager",
  7: "enums.operartor.type.partner",
  8: "enums.operartor.type.partner"
};
// 取消交易订单操作人类别
export const CANCEL_OPERATOR_TYPE = {
  0: "enums.operartor.type.self",
  1: "enums.operartor.type.multi",
  2: "enums.operartor.type.partner",
  3: "enums.operartor.type.manager"
};

//client授权状态
export const CLIENT_AUTHORIZATION_STATUS = {
  0: "enums.client.authorization.status.not", //未申请
  1: "enums.client.authorization.status.reviewing", //待用户同意
  2: "enums.client.authorization.status.reviewing", //待平台审核
  3: "enums.client.authorization.status.reviewing", //平台初审通过
  4: "enums.client.authorization.status.success", //平台复审通过
  5: "enums.client.authorization.status.client.reject", //用户拒绝
  6: "enums.client.authorization.status.platform.reject", //平台初审拒绝
  7: "enums.client.authorization.status.platform.reject", //平台复审拒绝
  8: "enums.client.authorization.status.success", //帐户冻结 但是未过期
  9: "enums.client.authorization.status.terminated", //用户终止授权
  10: "enums.client.authorization.status.expired" //帐户过期
};

// 定期理财列表申购状态
export const FIXED_YIELD_STATUS = {
  PUBLISHED: "enums.fixed.status.coming",
  LOCKED: "enums.fixed.status.locked"
};

// 定期理财历史记录的状态Status： START(1)-已创建, PROCESSING(2)-处理中, CONFIRMED(3)-已确认, REVOKED(4)-已撤销, FAILED(11)-失败
export const FIXED_YIELD_LOG_STATUS = {
  START: "enums.fixed.history.status.pending",
  PROCESSING: "enums.fixed.history.status.pending",
  CONFIRMED: "enums.fixed.history.status.success",
  COMPLETED: "enums.fixed.history.status.success",
  REVOKED: "enums.fixed.history.status.canceled",
  FAILED: "enums.fixed.history.status.fail"
};
// 新定期理财历史记录的状态Status: START(1)-已创建, PROCESSING(2)-处理中, CONFIRMED(3)-已确认, COMPLETED(4)-已完成, FAILED(11)-失败
export const NEW_FIXED_YIELD_LOG_STATUS: { [key: string]: string } = {
  START: "enums.fixed.history.status.pending",
  PROCESSING: "enums.fixed.history.status.pending",
  CONFIRMED: "enums.fixed.history.status.success",
  COMPLETED: "enums.fixed.history.status.success",
  FAILED: "enums.fixed.history.status.fail"
};

// 定期理财历史记录的操作类型Type: SUBSCRIBE(1)-申购, REVOKE(2)-撤销, BEARING(3)-计息, REDEEM(4)-赎回
export const FIXED_YIELD_OPERATE_TYPE = {
  SUBSCRIBE: "enums.fixed.operate.type.subscribe",
  REDEEM: "enums.fixed.operate.type.redeem"
};

// 定期理财持仓总记录的状态Status(合约状态): SIGNED(0)-已签约, LOCKED(1)-已锁定, REDEEM(2)-已赎回
export const FIXED_YIELD_CONTRACTS_STATUS = {
  SIGNED: "enums.fixed.contracts.status.signed",
  LOCKED: "enums.fixed.contracts.status.locked"
};

// 新定期理财持仓总记录的状态Status(合约状态): SIGNED(0)-确认中, IN_LOCKING(1)-持仓中, REDEEM(2)-已赎回
export const NEW_FIXED_YIELD_CONTRACTS_STATUS: { [key: string]: string } = {
  SIGNED: "enums.fixed.contracts.status.confirming",
  IN_LOCKING: "enums.fixed.contracts.status.positioning",
  IN_REDEEMING: "enums.fixed.contracts.status.redeeming"
};

// 策略类型
export const STRATEGY_TYPE = {
  0: "enums.strategy.type.quantitative",
  1: "enums.strategy.type.tracker",
  6: "enums.strategy.type.staking",
  8: "enums.strategy.type.fundamental"
};

// 策略历史订单列表状态
export const STRATEGY_ORDER_TYPE = {
  1: "enums.strategy.history.type.failed", // 扣款失败
  2: "enums.strategy.history.type.pending", // 已扣款
  3: "enums.strategy.history.type.failed", // 冻结失败
  4: "enums.strategy.history.type.pending", // 已冻结
  7: "enums.strategy.history.type.pending", // 赎回审批通过
  0: "enums.strategy.history.type.confirming", // 待冻结,待上传赎回数据
  11: "enums.strategy.history.type.canceled", // 已取消
  12: "enums.strategy.history.type.success" // 已成功
};
// 风投类型
export const VENTURE_TYPE = {
  0: "enums.venture.type.fund",
  1: "enums.venture.type.primaries",
  2: "enums.venture.type.secondaries",
  3: "enums.venture.type.token",
  4: "enums.venture.type.nft"
};

// fund类型
export const FUND_TYPE = {
  0: "enums.fund.type.fundamental"
};

// staking柱状图tab
export const STAKING_TAB = {
  aum: "AUM",
  subscriber: "Subscriber",
  session: "Session"
};

// trade柱状图tab
export const TRADE_TAB = {
  volume: "Volume",
  trader: "Trader",
  session: "Session",
  spotaum: "Spot AUM"
};

// 现货交易订单状态
export const SPOT_ORDER_STATUS = {
  created: "enums.spot.history.status.created",
  filled: "enums.spot.history.status.filled",
  cancelled: "enums.spot.history.status.cancelled",
  rejected: "enums.spot.history.status.rejected",
  fail: "enums.spot.history.status.fail"
};
// 现货交易订单买卖
export const SPOT_ORDER_SIDE = {
  buy: "enums.spot.history.side.buy",
  sell: "enums.spot.history.side.sell"
};
// 现货交易订单类型
export const SPOT_ORDER_TYPE = {
  limit: "priceOrBetter.text",
  market: "swap.text"
};
// 代客操作审批申请类型
export const APPLICATION_TYPE = {
  OTC_WITHDRAW: "enums.application.type.otc",
  CRYPTO_WITHDRAW: "enums.application.type.crypto"
};
// 代客操作审批申人
export const APPLICATION_REVIEWER = {
  0: "enums.application.reviever.main"
};
// 代客操作审批状态
export const APPLICATION_STATUS = {
  0: "enums.application.status.reviewing",
  1: "enums.application.status.processing",
  2: "enums.application.status.rejected",
  3: "enums.application.status.success",
  4: "enums.application.status.failed"
};

export enum EVipType {
  Silver = "Silver",
  Gold = "Gold",
  Black = "Black"
}

export enum IVipType {
  L0 = "Silver",
  L1 = "Gold",
  L2 = "Black"
}

export const Staking_Yield_Log_Status_text: { [key: string]: string } = {
  START: "enums.staking.history.status.pending", // Pending
  PROCESSING: "enums.staking.history.status.pending", // Pending
  CONFIRMED: "enums.staking.history.status.success", // Success
  COMPLETED: "enums.staking.history.status.success", // Success
  FAILED: "enums.staking.history.status.failed" // Failed
};
// Staking历史财作操作类型:SUBSCRIBE(1)-申购, REVOKE(2)-撤销, BEARING(3)-计息, REDEEM(4)-赎回
export const Staking_Yield_type_text: { [key: string]: string } = {
  SUBSCRIBE: "enums.staking.history.type.stake", // 质押
  REVOKE: "enums.staking.history.type.revoke", // 撤回
  BEARING: "enums.staking.history.type.rewards", // 收益
  REDEEM: "enums.staking.history.type.unstake" // 解仓
};

// 双币类型
export const STRUCTURED_DUAL_TYPE: { [key: string]: string } = {
  BuyLow: "BuyLow",
  SellHigh: "SellHigh"
};

export const DUAL_ORDER_STATUS: { [key: string]: string } = {
  accepted: "accepted",
  creating: "creating",
  created: "created",
  fail: "fail", //失败
  filling: "filling",
  filled: "filled", //成功
  rejecting: "rejecting",
  rejected: "rejected", //失败
  redeeming: "redeeming",
  redeemed: "redeemed",
  settling: "settling",
  settled: "settled"
};

//双币订单状态
export enum EDualOrderStatus {
  accepted = "accepted",
  creating = "creating",
  created = "created",
  fail = "fail", //失败
  filling = "filling",
  filled = "filled", //成功
  rejecting = "rejecting",
  rejected = "rejected", //失败
  redeeming = "redeeming",
  redeemed = "redeemed",
  settling = "settling",
  settled = "settled"
}

//累计期权订单状态
export enum EAccumulatorOrderStatus {
  fail = "fail", // 申购失败
  filled = "filled", // 已申购
  rejected = "rejected", // 申购失败
  partial_redeemed = "partial_redeemed", //赎回中
  settled = "settled", // 已到期
  settling = "settling" //赎回中
}

//累计期权订单状态
export const ACCUMULATOR_ORDER_STATUS = {
  fail: "enums.accumulator.status.fail", // 申购失败
  filled: "enums.accumulator.status.filled", // 已申购
  rejected: "enums.accumulator.status.rejected", // 申购失败
  partial_redeemed: "enums.accumulator.status.partial_redeemed", //赎回中
  settled: "enums.accumulator.status.settled", // 已到期
  settling: "enums.accumulator.status.settling", //赎回中
  terminal: "enums.accumulator.status.terminal" //已终止
};

//雪球订单状态
export const SNOWBALL_ORDER_STATUS = {
  filled: "snowball.order.filled", // 已申购
  fail: "snowball.order.fail", // 申购失败
  rejecting: "snowball.order.rejecting", // 申购失败
  rejected: "snowball.order.rejected", // 申购失败
  redeeming: "snowball.order.redeeming", //赎回中
  redeemed: "snowball.order.redeemed", //赎回中
  settled: "snowball.order.settled", // 已结算
  settling: "snowball.order.settling" //已结算
};

//雪球订单状态
export enum ESnowballOrderStatus {
  fail = "fail", // 申购失败
  filled = "filled", // 已申购
  rejecting = "rejected", // 申购失败
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  rejected = "rejected", // 申购失败
  redeeming = "redeeming", //赎回中
  redeemed = "redeemed", //赎回中
  settled = "settled", // 已到期
  settling = "settling" //赎回中
}

//雪球产品状态
export const SNOWBALL_PRODUCT_STATUS = {
  filled: "snowball.product.observe", // 观察中
  redeeming: "snowball.product.ended", // 已结束
  redeemed: "snowball.product.ended", // 已结束
  settled: "snowball.product.ended", // 已结束
  settling: "snowball.product.ended" // 已结束
};

export const SNOWBALL_SETTLED_STATUS = [
  ESnowballOrderStatus.redeeming,
  ESnowballOrderStatus.redeemed,
  ESnowballOrderStatus.settled,
  ESnowballOrderStatus.settling
];

export const SNOWBALL_VIEW_DESC_STATUS = SNOWBALL_SETTLED_STATUS.concat([
  ESnowballOrderStatus.filled
]);

// 双币历史订单状态 settled subscribed Redeeming Failed
export const STRUCTURED_LOG_STATUS = {
  fail: "enums.structured.status.failed", // 失败
  rejected: "enums.structured.status.failed", // 失败
  filled: "enums.structured.status.subscribed", // 已申购
  redeeming: "enums.structured.status.redeeming", // 赎回中
  redeemed: "enums.structured.status.redeeming", // 赎回中
  settling: "enums.structured.status.redeeming", // 赎回中
  settled: "enums.structured.status.settled" // 已结算
};
// kyc2 状态映射
export const KYC_STATUS = {
  1: "Auditing",
  4: "Auditing",
  5: "Rejected",
  6: "Certified"
};

// ICurrencyAssetQuotas
export enum ECurrencyAssetQuotasPeriod {
  once = "ONCE",
  daily = "DAILY",
  monthly = "MONTHLY"
}

// KYC展示信息key枚举
export const KYC_INSTITUTIONS_KEYS_LIST = [
  "name",
  "businessNature",
  "brNumber",
  "companyRegistrationCountry",
  "contactAddress",
  "companyType",
  "representativeName",
  "representativeTitle",
  "email",
  "companyRepresentativeNationality",
  "companyRepresentativeGender",
  "companyRepresentativeBirthDate",
  "phone",
  // 旧kyc问卷
  "sourceOfFund",
  "liquidAssets",
  "totalAssets",
  //新问卷调查
  "expectedAnnualTurnover",
  "netAssetValue",
  "percentageOfNAVInvestment",
  "sourceOfNAV",
  "mainBusinessNature",
  //个人、机构公共字段
  "investmentExperience",
  "expectedHoldingPeriod",
  "investmentObjective",
  "lossTolerance",
  "actionDownByPercent20"
];
export const KYC_INDIVIDUALS_KEYS_LIST = [
  "name",
  "age",
  "gender",
  "birthday",
  "countryCode",
  "nationality",
  "passportId",
  "contactAddress",
  "phone",
  "email",
  // 旧kyc问卷，只用作旧数据显示
  "annualIncome",
  "sourceOfIncome",
  "sourceOfFund",
  "liquidAssets",
  "totalAssets",
  //新问卷调查
  "personalAnnualIncome",
  "liquidNetWorth",
  "percentageOfNetWorthInvestment",
  "sourceOfNetWorth",
  "employmentStatus",
  //个人、机构公共字段
  "investmentExperience",
  "expectedHoldingPeriod",
  "investmentObjective",
  "lossTolerance",
  "actionDownByPercent20"
];
// kyc基础信息 每个key对应label的文案
export const KYC_INSTITUTIONS_LABEL_TEXT = {
  name: "kyc.certify.institutions.name",
  businessNature: "kyc.certify.institutions.business.nature",
  brNumber: "kyc.certify.institutions.taxIdentificationNumber",
  companyRegistrationCountry: "kyc.certify.institutions.CountryIncorporation",
  contactAddress: "kyc.certify.institutions.address",
  companyType: "kyc.certify.institutions.entity.type",
  representativeName: "kyc.certify.institutions.authorizedName",
  representativeTitle: "kyc.certify.institutions.authorizedTitle",
  phone: "kyc.certify.institutions.authorizedPhone",
  email: "kyc.certify.institutions.authorizedEmail",
  companyRepresentativeNationality: "kyc.individual.nationality",
  companyRepresentativeGender: "kyc.individual.gender",
  companyRepresentativeBirthDate: "kyc.individual.birth",
  // 旧问卷数据仅作展示
  sourceOfFund: "kyc.certify.old.data.institutions.label.sourceOfFund",
  liquidAssets: "kyc.certify.old.data.institutions.label.liquidAssets",
  totalAssets: "kyc.certify.old.data.institutions.label.totalAssets"
};
export const KYC_INDIVIDUALS_LABEL_TEXT = {
  name: "kyc.individual.fullname",
  birthday: "kyc.individual.birth",
  age: "kyc.individual.age",
  gender: "kyc.individual.gender",
  nationality: "kyc.individual.nationality",
  passportId: "kyc.individual.cardId",
  phone: "kyc.certify.institutions.authorizedPhone",
  email: "kyc.individual.email",
  contactAddress: "kyc.individual.address",
  // 旧问卷数据仅作展示
  annualIncome: "kyc.certify.old.data.individual.label.annualIncome",
  sourceOfIncome: "kyc.certify.old.data.individual.label.sourceOfIncome",
  sourceOfFund: "kyc.certify.old.data.individual.label.sourceOfFund",
  liquidAssets: "kyc.certify.old.data.individual.label.liquidAssets",
  totalAssets: "kyc.certify.old.data.individual.label.totalAssets"
};
export const KYC_RISK_LEVEL = {
  low: "LOW_RISK",
  medium: "MEDIUM_RISK",
  high: "HIGH_RISK"
};

export const KYC_TYPE_QUERY = {
  institutions: 0,
  individuals: 1
};

/**
 * 余额类型
 */
export enum BalanceCategory {
  Spot = 0, // 0-现货
  Flexible = 4 // 4-活期理财
}

/**
 * 定投产品
 */
export enum AIPProductType {
  Normal, // 0-普通产品
  Preset, // 1-预设产品
  Customize // 2-自定义产品
}

/**
 * 定投策略
 */
export enum AIPStrategyType {
  Normal, // 0-普通策略
  Preservation, // 1-保本策略
  Financing // 2-理财定投策略
}

export const WITHDRAW_WHITELIST_STATUS: { [key: string]: string } = {
  UNAPPROVED: "wallet.withdraw.white.status.reviewing",
  REJECTED: "wallet.withdraw.white.status.failed",
  APPROVED: "wallet.withdraw.white.status.success"
};
export const WALLET_WHITELIST_STATUS: { [key: string]: string } = {
  CREATED: "enums.wallet.status.created",
  APPROVED: "enums.wallet.status.approved",
  REJECTED: "enums.wallet.status.rejected",
  DELETED: "enums.wallet.status.deleted"
};

export const WITHDRAW_WHITELIST_TYPE: { [key: string]: string } = {
  all: "",
  whitelist: "WHITELIST",
  nonWhitelist: "NORMAL"
};
export const WALLET_WHITELIST_TAB_TYPE: { [key: string]: string } = {
  Whitelist: "Whitelist",
  InReview: "InReview"
};
export const WITHDRAW_ADDRESS_ORIGIN_TYPE: { [key: string]: string } = {
  Exchange: "Exchange",
  Wallet: "Wallet"
};

/**
 * venture shares
 */
export const VENTURE_SHARES: { [key: number]: string } = {
  0: "ventures.primary.tab",
  1: "ventures.secondary.tab"
};

// 活期产品是否展示自动续投开关 0不展示，1展示
export enum AUTO_SUBSCRIBE {
  CLOSE = 0,
  OPEN = 1
}

// 活期产品是否展示利息滚投开关 0不展示，1展示
export enum INTEREST_TYPE {
  CLOSE = 0,
  OPEN = 1
}

// 活期利息滚投模式 0单利，1复利
export enum CONTRACT_INTEREST_TYPE {
  SIMPLE = 0,
  COMPOUND = 1
}

// 定期合约状态 1进行中，2失败，3取消
export enum INVEST_CONTRACT_STATUS {
  active = "1",
  failure = "2",
  cancelled = "3"
}

export const OPERATION_CMS_TABS = {
  dashboard: "dashboard",
  customer: "customer",
  product: "product"
};

export const CLIENT_PRODUCT_TABS = {
  Venture: "Venture",
  Staking: "Staking",
  Trade: "Trade",
  Structured: "Structured"
};

export enum SPOT_TYPE {
  DEPOSIT = 0,
  WITHDRAW = 1
}

export enum LOGIN_PAGE_TYPE {
  LOGIN = "LOGIN",
  VERIFY = "VERIFY"
}

export enum ACCOUNT_TYPE {
  EMAIL = "EMAIL",
  PHONE = "PHONE"
}

export enum VERIFY_TYPE {
  google = "google",
  email = "email",
  phone = "phone",
  unrequired = "unrequired"
}

// 业务安全校验BusinessType
export enum IBindingBusinessType {
  forgotPassword = 1,
  applyWithdraw = 4,
  resetPassword = 6,
  changeBind = 10, // 更改手机号，解绑手机号都是10
  bindPhone = 13,
  disableGoogle = 16,
  phoneLogin = 17,
  withdrawAddAddress = 24, //新增提币地址
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  withdrawWhiteList = 24, //加入提币白名单
  withdrawWhiteListOnAndOff,
  flowableApprove,
  vaultWalletDeposit, //冷钱包充币
  generateGoogleKey,
  vaultWalletWithdraw, //冷钱包提币
  whitelistAddAddress // 新增白名单地址
}

// 事件名，目前业务只针对ApplyWithdraw做校验
export enum IUserEventEnums {
  ApplyWithdraw = "ApplyWithdraw"
}

export const KYC_UPLOAD_RISK_COUNT = {
  LOW_RISK: 1,
  MEDIUM_RISK: 2,
  HIGH_RISK: 3
};

export const BTC_YTD_START_YEAR = 2023;

/**
 * 定投状态
 */
export enum AIPStrategyStatus {
  Sign, // 0 申购
  Running, // 1 进行中
  Pause, // 2 暂停
  UserStop // 3 用户手动终止清空所有收益
}

export enum IDcaProductsStatusEnums {
  SIGN = "0",
  RUNNING = "1",
  PAUSE = "2",
  USER_STOP = "3"
}

export enum IDcaProductsPauseTypeEnums {
  RUNNING, //没暂停
  cancelledManually, //用户手动暂停
  userError, //用户状态异常暂停
  transferBySystem, //系统扣费失败暂停
  planCancelled = 10, //投资产品状态异常暂停
  systemError, //系统认购失败暂停
  redeemedAll //用户活期全部赎回 终止计划
}

export enum CMS_PRODUCT_TYPE {
  venture = "venture",
  staking = "staking",
  trade = "trade"
}

export enum CMS_SORT_TYPE {
  AUM_UP = "AUM_UP",
  AUM_DOWN = "AUM_DOWN",
  YTD_UP = "YTD_UP",
  YTD_DOWN = "YTD_DOWN",
  LAST_LOGIN_UP = "LAST_LOGIN_UP",
  LAST_LOGIN_DOWN = "LAST_LOGIN_DOWN"
}

export enum CMS_VENTURE_SORT_TYPE {
  HOLD_COUNT_VENTURE_DOWN = "HOLD_COUNT_VENTURE_DOWN",
  HOLD_COUNT_VENTURE_UP = "HOLD_COUNT_VENTURE_UP",
  HOLD_AUM_VENTURE_DOWN = "HOLD_AUM_VENTURE_DOWN",
  HOLD_AUM_VENTURE_UP = "HOLD_AUM_VENTURE_UP",
  AUM_PROPORTION_VENTURE_DOWN = "AUM_PROPORTION_VENTURE_DOWN",
  AUM_PROPORTION_VENTURE_UP = "AUM_PROPORTION_VENTURE_UP",
  LATEST_VIEW_TIME_DOWN = "LATEST_VIEW_TIME_DOWN",
  LATEST_VIEW_TIME_UP = "LATEST_VIEW_TIME_UP",
  VISIT_DAYS_DOWN = "VISIT_DAYS_DOWN",
  VISIT_DAYS_UP = "VISIT_DAYS_UP",
  VISIT_COUNT_DOWN = "VISIT_COUNT_DOWN",
  VISIT_COUNT_UP = "VISIT_COUNT_UP",
  HOLD_CURR_VENTURE_AUM_DOWN = "HOLD_CURR_VENTURE_AUM_DOWN",
  HOLD_CURR_VENTURE_AUM_UP = "HOLD_CURR_VENTURE_AUM_UP"
}

export enum CMS_STAKING_SORT_TYPE {
  HOLD_COUNT_STAKING_DOWN = "HOLD_COUNT_STAKING_DOWN",
  HOLD_COUNT_STAKING_UP = "HOLD_COUNT_STAKING_UP",
  HOLD_AUM_STAKING_DOWN = "HOLD_AUM_STAKING_DOWN",
  HOLD_AUM_STAKING_UP = "HOLD_AUM_STAKING_UP",
  AUM_PROPORTION_STAKING_DOWN = "AUM_PROPORTION_STAKING_DOWN",
  AUM_PROPORTION_STAKING_UP = "AUM_PROPORTION_STAKING_UP",
  LATEST_VIEW_TIME_DOWN = "LATEST_VIEW_TIME_DOWN",
  LATEST_VIEW_TIME_UP = "LATEST_VIEW_TIME_UP",
  VISIT_DAYS_DOWN = "VISIT_DAYS_DOWN",
  VISIT_DAYS_UP = "VISIT_DAYS_UP",
  VISIT_COUNT_DOWN = "VISIT_COUNT_DOWN",
  VISIT_COUNT_UP = "VISIT_COUNT_UP",
  HOLD_CURR_STAKING_AUM_DOWN = "HOLD_CURR_STAKING_AUM_DOWN",
  HOLD_CURR_STAKING_AUM_UP = "HOLD_CURR_STAKING_AUM_UP"
}

export enum CMS_TRADE_SORT_TYPE {
  TRADE_TIME_DOWN = "TRADE_TIME_DOWN",
  TRADE_FILLED_VOLUME_UP = "TRADE_FILLED_VOLUME_UP",
  TRADE_FILLED_VOLUME_DOWN = "TRADE_FILLED_VOLUME_DOWN"
}
